import * as React from "react";
import {
  FacebookShareButton,
  TwitterShareButton,
  HatenaShareButton,
  LineShareButton,
  FacebookIcon,
  TwitterIcon,
  HatenaIcon,
  LineIcon,
} from "react-share";

interface ShareButtonsProps {
  url: string;
  title: string;
}

export const ShareButtons: React.FC<ShareButtonsProps> = ({ url, title }) => {
  return (
    <div className="flex gap-4 py-8">
      <TwitterShareButton url={url} title={title}>
        <TwitterIcon size={48} round />
      </TwitterShareButton>
      <FacebookShareButton url={url} title={title}>
        <FacebookIcon size={48} round />
      </FacebookShareButton>
      <HatenaShareButton url={url} title={title}>
        <HatenaIcon size={48} round />
      </HatenaShareButton>
      <LineShareButton url={url} title={title}>
        <LineIcon size={48} round />
      </LineShareButton>
    </div>
  );
};
