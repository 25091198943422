import dayjs from "dayjs";
import { graphql, Link, PageProps } from "gatsby";
import { GatsbySeo, GatsbySeoProps } from "gatsby-plugin-next-seo";
import * as React from "react";

import { Layout } from "@/components/Layout";
import { ShareButtons } from "@/components/ShareButtons";

const contentOnError = `
    <div>
        <p>Something went wrong...</p>
        <p>申し訳ありません、トラブルが発生したようです</p>
    </div>
`;

const PostPage: React.FC<PageProps<Queries.PostQuery>> = ({ data }) => {
  const post = data.contentfulPost;
  const content = post?.content?.childMarkdownRemark?.html ?? contentOnError;
  const pageUrl = data.site?.siteMetadata?.siteUrl + "/post/" + post?.slug;

  const formatedCreatedAt = dayjs(post?.createdAt).format("MMM D");
  // const formatedUpdatedAt = dayjs(post?.updatedAt).format("MMM D");

  const seoProps: GatsbySeoProps = {
    title: post?.title ?? "Post",
    description: post?.content?.childMarkdownRemark?.excerpt ?? undefined,
    openGraph: {
      title: post?.title ?? "Post",
      description: post?.content?.childMarkdownRemark?.excerpt ?? undefined,
      url: pageUrl,
      article: {
        publishedTime: post?.createdAt ?? "",
        // authors:[]
        tags: post?.metadata?.tags?.map((tag) => tag?.name ?? "ビジネス"),
        section: "ビジネス",
      },
    },
  };

  return (
    <Layout>
      <GatsbySeo {...seoProps} />
      <div className="prose max-w-full pt-12 pb-4">
        <h1 className="md:text-6xl">{post?.title}</h1>
      </div>

      <p className="text-gray-500">
        <time>{formatedCreatedAt}</time>
        {"・"}
        <span>{post?.content?.childMarkdownRemark?.timeToRead} min read</span>
      </p>
      <div className="flex items-center gap-2 text-gray-500">
        <span>tag:</span>
        <ul className="flex list-none flex-wrap gap-4">
          {post?.metadata?.tags?.map((tag) => (
            <li key={tag?.contentful_id}>
              <Link
                to={"/tag/" + tag?.contentful_id}
                className="transition-all hover:text-gray-700 hover:underline"
              >
                #{tag?.name}
              </Link>
            </li>
          ))}
        </ul>
      </div>

      <div
        className="prose my-12 max-w-full prose-h2:border-l-4 prose-h2:border-yellow-400 prose-h2:pl-2 prose-a:text-yellow-600"
        dangerouslySetInnerHTML={{ __html: content }}
      />

      <ShareButtons url={pageUrl} title={post?.title ?? ""} />
    </Layout>
  );
};

export const query = graphql`
  query Post {
    site {
      siteMetadata {
        siteUrl
      }
    }
    contentfulPost {
      createdAt
      # updatedAt
      content {
        childMarkdownRemark {
          html
          timeToRead
          excerpt
        }
      }
      title
      slug
      metadata {
        tags {
          name
          contentful_id
        }
      }
    }
  }
`;

export default PostPage;
